var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("panel-group"),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card" },
                [
                  _c("el-tag", { attrs: { type: "danger" } }, [_vm._v("说明")]),
                  _c("p"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "auth-box", attrs: { gutter: 0 } },
        [_c("el-col", { staticClass: "auth-col", attrs: { span: 6 } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }